.cm-s-material.CodeMirror {
  min-height: 80vh;
  background-color: #333333;
  color: white;
}
.react-codemirror2 {
  border: solid 1px white;
}
.cm-s-material .CodeMirror-gutters {
  background-color: rgb(81, 81, 81);

  z-index: 0;

  /* border-right: dotted white 1px; */
}
